<template>
	<div class="bigBox">
		<h2>权限管理</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<div class="row-me row-center addDiv" v-if="this.powerListName.includes('新增按钮')" @click="addPre">
					<img src="../../assets/img/icon_xzyh.png" class="iconImg" />&nbsp;
					<span class="youzhi">新建权限</span>
				</div>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="perArr" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="code" label="权限编号" align="center">
					</el-table-column>
					<el-table-column prop="name" label="权限名称" align="center">
					</el-table-column>
					<el-table-column prop="create_time" label="创建时间" align="center">
					</el-table-column>
					<el-table-column label="操作" align="left" width="196">
						<template #default="scope">
							<el-button type="text" size="small" v-if="this.powerListName.includes('授权按钮')"
								@click="pres(scope.row)">授权</el-button>
							<el-button type="text" size="small" v-if="this.powerListName.includes('修改按钮')"
								@click="addPre(scope.row)">编辑</el-button>
							<el-popconfirm title="确定删除吗?" @confirm="preDel(scope.row)"><template #reference>
									<el-button type="text" size="small"
										v-if="this.powerListName.includes('删除按钮')">删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>

			</div>
			<div class="row-me row-center flex-end1 margin-top10" v-if="perArr.length>0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page.sync="pageNo" :total="total">
				</el-pagination>
			</div>
		</div>

		<!-- 新建权限 -->
		<el-dialog v-model="dialogPre" :title="preTit" width="30%">
			<el-form :model="form" label-width="120px" label-position="right">
				<el-form-item label="权限编号">
					<el-input v-model="form.code" placeholder="请输入" style="width: 400px;" />
				</el-form-item>
				<el-form-item label="权限名称">
					<el-input v-model="form.name" placeholder="请输入" style="width: 400px;" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogPre = false" style="width: 100px;height: 40px;">取消</el-button>
					<el-button type="primary" @click="preOk">
						提交
					</el-button>
				</span>
			</template>
		</el-dialog>

		<!-- 配置权限 -->
		<el-drawer v-model="drawer" v-if="drawer" title="配置权限" direction="rtl">
			<!-- <el-tree :data="data" ref="tree" show-checkbox node-key="id" :default-expanded-keys="[1,2, 3]"
				:default-checked-keys="[5]" :props="defaultProps">
			</el-tree> -->
			<!-- <el-tree :data="data" ref="tree" show-checkbox node-key="id" :default-checked-keys="checkedId"
				:props="defaultProps">
			</el-tree> -->

			<!-- <el-tree :data="data" node-key="id" :default-checked-keys="checkedId" show-checkbox check-strictly
				ref="tree" :props="defaultProps" @check="hanleCheck"></el-tree> -->

			<el-tree :data="data" :props="defaultProps" node-key="id" show-checkbox check-strictly ref="tree"
				:default-checked-keys="checkedId" @check="hanleCheck">
			</el-tree>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="drawer = false" style="width: 100px;height: 40px;">取消</el-button>
					<el-button type="primary" @click="preYes">
						保存
					</el-button>
				</span>
			</template>
		</el-drawer>
	</div>
</template>

<script>
	import {
		sysRoleInfoList,
		sysRoleInfoAdd,
		sysRoleInfoDel,
		sysMenuListInfo,
		sysRolePermissionAdd
	} from "@/api/systemManagement/systemManagement.js"
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				dialogPre: false,
				drawer: false,
				form: {
					name: "",
					code: "",
				},
				perArr: [],
				pageNo: 1,
				total: 0,
				data: [],
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				id: '',
				perArrPage: "",
				checkedId: [], //选择的id
				powerListName: [], //权限按钮
			}
		},
		mounted() {
			var list = JSON.parse(sessionStorage.getItem('menuArrayRegulate'));
			console.log(list, 'listlist');
			var nameList = [];

			for (var i = 0; i < list.length; i++) {
				if (list[i].name == "系统管理") {
					for (var j = 0; j < list[i].children.length; j++) {

						if (list[i].children[j].name == "权限管理") {
							nameList = list[i].children[j].children;
						}
					}
				}
			}

			if (nameList != undefined) {
				for (var i = 0; i < nameList.length; i++) {
					this.powerListName.push(nameList[i].name)
				}
			}
			console.log(this.powerListName, '最后按钮')
			this.getPerList();
			this.sysMenuListInfoWay()
		},
		methods: {
			hanleCheck(data, node) {
				const _this = this
				// 获取当前节点是否被选中
				const isChecked = this.$refs.menuTree.getNode(data).checked
				// 如果当前节点被选中，则遍历上级节点和下级子节点并选中，如果当前节点取消选中，则遍历下级节点并取消选中
				if (isChecked) {
					// 判断是否有上级节点，如果有那么遍历设置上级节点选中
					data.level && setParentChecked(data.level)
					// 判断该节点是否有下级节点，如果有那么遍历设置下级节点为选中
					data.menuList && setChildreChecked(data.menuList, true)
				} else {
					// 如果节点取消选中，则取消该节点下的子节点选中
					data.menuList && setChildreChecked(data.menuList, false)
				}

				function setParentChecked(parentId) {
					// 获取该id的父级node
					const parentNode = _this.$refs.menuTree.getNode(level)
					// 如果该id的父级node存在父级id则继续遍历
					parentNode && parentNode.data && parentNode.data.level && setParentChecked(parentNode.data.level)
					//  设置该id的节点为选中状态
					_this.$refs.menuTree.setChecked(parentId, true)
				}

				function setChildreChecked(node, isChecked) {
					node.forEach(item => {
						item.menuList && setChildreChecked(item.menuList, isChecked)
						_this.$refs.menuTree.setChecked(item.id, isChecked)
					})
				}
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getPerList()
			},
			// 表格数据
			async getPerList() {
				const res = await sysRoleInfoList({
					page: this.pageNo,
				})
				this.perArr = res.data.listInfo
				this.total = res.data.allListNumber
			},
			addPre(row) {
				if (row.id) {
					this.preTit = '编辑权限'
					this.id = row.id
					this.form = JSON.parse(JSON.stringify(row))
				} else {
					this.preTit = '新建权限'
					this.form.name = ""
					this.form.code = ""
				}
				this.dialogPre = true
			},
			// 新建编辑保存btn
			async preOk() {
				if (this.id = '') {
					const res = await sysRoleInfoAdd(this.form)
				} else {
					const res1 = await sysRoleInfoAdd({
						id: this.id,
						...this.form
					})
				}
				this.form.name = ""
				this.form.code = ""
				this.dialogPre = false
				this.getPerList()
			},
			// 点击删除
			async preDel(row) {
				const res = await sysRoleInfoDel({
					id: row.id
				})
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				this.getPerList()
			},
			// 获取树结构
			async sysMenuListInfoWay() {
				const res = await sysMenuListInfo();
				this.data = res.data
			},
			// 点击授权
			async pres(row) {
				// this.data = [];
				// const res = await sysMenuListInfo();
				// this.data = res.data

				this.$nextTick(() => {
					this.checkedId = [];
					this.checkedId = row.menu_id;
					this.$forceUpdate()

					console.log(this.checkedId, 'this.checkedIdthis.checkedIdthis.checkedId')

					this.id = row.id;
					this.drawer = true;
					this.$forceUpdate()
				})



			},
			// 授权保存按钮
			async preYes() {

				// let res = this.$refs.tree.getCheckedNodes()
				// let arr = []
				// res.forEach((item) => {
				// 	arr.push(item.id)
				// })
				if (this.$refs.tree.getCheckedKeys().length == 0) {
					this.$message.error("请先选择权限");
					return false;
				}

				this.$post('/admin/sysRolePermissionAdd', {
					role_id: this.id,
					menu_id: this.$refs.tree.getCheckedKeys()
				}).then((res) => {
					if (res) {
						this.getPerList()
						this.$message.success("保存成功")
						this.drawer = false
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})

				// ElMessage({
				// 	message: '授权成功',
				// 	type: 'success',
				// })
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.addDiv {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 7px 0px;
		cursor: pointer;
		width: 128px;
		justify-content: center;

		.iconImg {
			width: 26px;
			height: 26px;
		}

		.youzhi {
			font-size: 18px;
		}
	}

	.agains {
		margin-left: 10px;
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-form-item__label {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		color: #252630;
		margin-top: 8px;
	}

	::v-deep .el-drawer__title {
		font-size: 18px;
		color: #000;
		font-weight: 400;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>